import React, { useState } from "react"

// Libraries
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import Grid from "components/grid/"
import BlogCard from "components/blog-card/"
import ExternalLinkButton from "components/external-link-button"
import { Button } from "components/button"

const StyledPosts = styled.section`
  .blog__categories {
    margin-bottom: 40px;

    ${breakpoint.medium`
      margin-bottom: 64px;
    `}

    .categories--mobile {
      display: flex;

      ${breakpoint.medium`
        display: none;
      `}
    }

    .categories--desktop {
      display: none;

      ${breakpoint.medium`
        display: flex;
        border-bottom: 2px solid ${colors.cloudy};
      `}

      .category {
        position: relative;
        margin-right: 14px;
        padding: 0 18px 20px 18px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;

        &:after {
          content: "";
          width: 100%;
          height: 4px;
          position: absolute;
          bottom: -2px;
          left: 0;
          background-color: transparent;
          transition: all 0.3s ease;
        }

        &.active {
          color: ${colors.indiblue};

          &:after {
            background-color: ${colors.indiblue};
          }
        }
      }
    }
  }

  .posts--featured {
    padding: 90px 0 64px 0;

    ${breakpoint.medium`
      padding: 120px 0;
    `}

    .featured-post {
      &:hover {
        background-color: transparent;
        color: ${colors.stout};

        .card__content {
          .categories {
            p {
              color: ${colors.indiblue};
            }
          }
        }
      }

      ${breakpoint.medium`
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::after {
          content: "";
          width: 0;
          height: 2px;
          position: absolute;
          right: 32px;
          bottom: 0;
          display: block;
          background-color: ${colors.indiblue};
          box-sizing: border-box;
          transition: all 0.3s ease;
        }

        &:hover {

          &::after {
            width: calc(42% - 64px);
          }
        }

        .card__image {
          width: 58%;
          margin: 0;
        }

        .card__content {
          width: 42%;
          padding: 0 32px;

          .title {
            font-size: 24px;

            ${breakpoint.medium`
              font-size: 32px;
            `}
          }

          .categories {
            margin-bottom: 16px;
          }

          .excerpt {
            margin-top: 24px;
          }
        }
      `}

      .title {
        font-size: 24px;
        line-height: 1.2em;

        ${breakpoint.medium`
        font-size: 32px;
      `}
      }
    }
  }

  .posts {
    padding-top: 60px;
    background-color: ${colors.cream};

    &.aos-animate {
      .grid__item {
        opacity: 1;

        ${breakpoint.small`
          transform: translateY(0);
        `}
      }
    }

    ${breakpoint.medium`
      padding-top: 120px;
    `}

    ${Container} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .grid__item {
      opacity: 0;
      transition: all 0.3s ease-in-out;

      @media screen and (min-width: 768px) and (max-width: 1023px) {
        width: calc((100% - 32px) / 2);
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      ${breakpoint.small`
        // opacity: 1;
        transform: translateY(64px);
      `}
    }

    .post {
      height: 100%;
      padding: 12px;
      margin-bottom: 32px;

      .excerpt {
        display: none;
      }

      .card__content {
        padding: 0 4px;
      }
    }

    .posts__read-more {
      width: 100%;
      margin-top: 64px;
      text-align: center;

      .read-more__button {
        padding: 16px 32px;
        color: ${colors.midnight};
        font-size: 18px;
        font-weight: 500;
        border: 2px solid ${colors.midnight};
        border-radius: 100px;
        cursor: pointer;
      }
    }
  }
`

const Posts = ({ data: posts }) => {
  // Props
  const featuredPost = posts[0]

  const [postsToShow, setPostsToShow] = useState(10)

  const handleLoadMore = () => {
    setPostsToShow(postsToShow + 9)
  }

  return (
    <StyledPosts>
      <Container>
        <div className="posts--featured">
          <BlogCard className="featured-post" {...featuredPost} />
        </div>
      </Container>

      <div className="posts" data-aos="fade">
        <Container>
          <Grid gutter="32px" columns="3">
            {posts.slice(0, postsToShow).map((post, index) => {
              if (index !== 0) {
                return (
                  <div
                    className="grid__item"
                    style={{
                      transitionDelay: index * 100 + "ms",
                    }}
                  >
                    <BlogCard key={index} className="post" {...post} />
                  </div>
                )
              } else return null
            })}
          </Grid>

          {postsToShow < posts.length && (
            <div className="posts__read-more">
              <button
                type="button"
                className="read-more__button"
                onClick={handleLoadMore}
              >
                <Trans>Load more posts</Trans>
              </button>
            </div>
          )}
        </Container>
      </div>
    </StyledPosts>
  )
}

export default Posts
