import React from "react"

// Libraries
import styled from "styled-components"
import { Link } from "gatsby-plugin-react-i18next"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

const StyledBlogCard = styled(Link)`
  width: 100%;
  height: 100%;
  display: block;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${colors.white};
  transition: all 0.3s ease;
  box-sizing: border-box;

  &:hover {
    background-color: ${colors.midnight};
    color: ${colors.white};

    .card__image {
      .image {
        transform: scale(1.05);
      }
    }

    .card__content {
      .categories {
        p {
          color: ${colors.supernova};
        }
      }
    }
  }

  .card__image {
    width: 100%;
    margin-bottom: 16px;
    overflow: hidden;

    .image {
      width: 100%;
      visibility: visible;
      transition: all 0.3s ease;
    }
  }

  .card__content {
    width: 100%;

    .categories {
      margin-bottom: 8px;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 16px;

      ${breakpoint.medium`
        font-size: 14px;
      `}

      p {
        display: inline-block;
        margin-right: 8px;
        color: ${colors.indiblue};

        &:last-child {
          margin: 0;
        }
      }
    }

    .excerpt {
      margin-top: 16px;
    }
  }
`

const BlogCard = props => {
  const { className, categories, title, slug, excerpt, featuredImage } = props

  return (
    <StyledBlogCard to={"/blog/" + slug + "/"} className={className}>
      <div className="card__image">
        <GatsbyImage className="image" image={getImage(featuredImage)} alt="" />
      </div>
      <div className="card__content">
        <div className="categories">{categories && <p>{categories[0]}</p>}</div>
        <p className="title">{title}</p>
        <p className="excerpt paragraph--small">{excerpt && excerpt.excerpt}</p>
      </div>
    </StyledBlogCard>
  )
}

export default BlogCard
