import React, { useState } from "react"

// Libraries
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables/"

// Components
import Observer from "components/observer/"
import Container from "components/container/"
import Lottie from "components/lottie-observed/"

// Animation
import Animation from "assets/animations/blog/hero-animation.json"

const StyledHero = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${colors.midnight};
  color: ${colors.white};
  padding: 120px 0 80px 0;

  ${breakpoint.medium`
    height: 740px;
    padding: 0;
  `}

  ${Container} {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .hero__content {
    max-width: 538px;
    margin: 0 auto;

    ${breakpoint.medium`
      margin: 0;
    `}
  }

  .hero__icon {
    max-width: 774px;
    width: 100%;

    ${breakpoint.medium`
      width: 50%;
      position: absolute;
      right: 0;
      display: flex;
    `}

    ${breakpoint.large`
      width: 60%;
      right: -10%;
    `}
  }
`

const Heading = styled.h2`
  font-weight: 400;

  span {
    color: ${props => (props.visible ? colors.supernova : colors.white)};
    font-family: ${fonts.primary};
    font-weight: 500;
    transition: all 1.5s;
    transition-delay: 0.6s;
  }
`

const Hero = () => {
  const [isVisible, setIsVisible] = useState(false)

  const handleVisiblity = () => {
    setIsVisible(true)
  }

  return (
    <StyledHero>
      <Container>
        <div className="hero__content">
          <Observer onVisible={handleVisiblity}>
            <Heading visible={isVisible}>
              <Trans>We believe everyone has a valuable story to share.</Trans>
              <br />
              <Trans>Our team is no exception.</Trans>
              <br />
              <span>
                <Trans>Welcome to our blog.</Trans>
              </span>
            </Heading>
          </Observer>
        </div>

        <div className="hero__icon">
          <Lottie
            animation={Animation}
            loop={true}
            isClickToPauseDisabled={true}
          />
        </div>
      </Container>
    </StyledHero>
  )
}

export default Hero
