import React, { useState } from "react"

// Libraries
import styled from "styled-components"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import Form from "components/forms/"

// Icons
import IconPlane from "assets/icons/components/newsletter-blog/icon-plane.inline.svg"
import IconSuccess from "assets/icons/components/newsletter-blog/icon-success.inline.svg"
import Loader from "../loader"

const StyledNewsletterBlog = styled.div`
  padding: 60px 0;

  ${breakpoint.medium`
    padding: 120px 0;
  `}

  .newsletter {
    position: relative;
    padding: 60px 24px;
    overflow: hidden;

    .newsletter__content {
      max-width: 928px;
      margin: 0 auto;

      .content {
        margin-bottom: 72px;

        ${breakpoint.medium`
          margin-bottom: 0;
        `}
      }

      .form {
        position: relative;
        transition: all 0.3s ease;

        &.fade-out {
          opacity: 0;
        }

        .form__group {
          position: relative;

          .message {
            position: absolute;
            top: calc(100% + 2px);
            font-size: 16px;
          }
        }

        button[type="submit"] {
          width: 150px;
          height: 57px;
          padding: 16px 32px;
          margin-top: 48px;
          font-size: 18px;
          font-weight: 500;
          line-height: 1.4em;
          border-radius: 100px;
        }
      }
    }
  }

  .icon-success {
    position: absolute;
    right: -10%;
    bottom: -12%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;

    ${breakpoint.medium`
      right: 72px;
      bottom: -40%;
    `}

    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }

  .icon {
    width: 160px;
    height: auto;
    position: absolute;
    right: -14px;
    bottom: -14px;
  }
`

const NewsletterBlog = () => {
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const handleInputChange = event => {
    const {
      target: { value },
    } = event

    setEmail(value)

    if (showError) {
      setShowError(false)
    }
  }

  const validateEmail = () => {
    const emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/
    return emailRegex.test(email)
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (validateEmail(email)) {
      setShowError(false)
      setLoading(true)

      // Data
      const data = {
        fields: [
          {
            name: "email",
            value: email,
          },
          {
            name: "lifecyclestage",
            value: "subscriber",
          },
        ],
        context: {
          pageUri: window.location.href,
        },
      }

      // Post request
      fetch(
        "https://api.hsforms.com/submissions/v3/integration/submit/2094487/84c23991-fa1c-4c37-87ea-e1ed14d3f498",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Content-Length": data.length,
          },
          body: JSON.stringify(data),
        }
      )
        .then(response => {
          if (response.status === 200) {
            setLoading(false)
            setShowError(false)
            setShowSuccess(true)
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      if (!showError) {
        setShowError(true)
      }
    }
  }
  const { t } = useTranslation()

  return (
    <StyledNewsletterBlog className="bg--cream">
      <Container>
        <div className="newsletter d-flex align-items-center bg--white">
          <div className="newsletter__content row align-items-center justify-content-between">
            <div className="content col-12 col-md-6">
              {!showSuccess ? (
                <>
                  <h3 className="color--indiblue mb-3">
                    <Trans>Intrigued by our stories?</Trans>
                  </h3>
                  <p className="paragraph--small">
                    <Trans>
                      Subscribe to our newsletter to stay up-to-date on Indi’s
                      latest projects and articles
                    </Trans>
                  </p>
                </>
              ) : (
                <div data-aos="fade-in">
                  <h3 className="color--indiblue mb-3">
                    <Trans>Thanks for subscribing!</Trans>
                  </h3>
                  <p className="paragraph--small">
                    <Trans>
                      Our next newsletter is already in the works and will
                      arrive in your inbox when it’s ready
                    </Trans>
                  </p>
                </div>
              )}
            </div>

            <div className="col-12 col-md-5">
              <Form
                onSubmit={handleSubmit}
                className={showSuccess ? "form fade-out" : "form"}
                noValidate
              >
                <div className="form__group mb-0">
                  <label htmlFor="email" className="form__label">
                    <Trans>Enter your email</Trans>
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder={t("theking@thenorth.com")}
                    onChange={handleInputChange}
                  />
                  {showError && (
                    <small className="message color--errorRed">
                      <Trans>Oopsie! This email is not valid.</Trans>
                    </small>
                  )}
                </div>

                <button
                  type="submit"
                  className="bg--indiblue bg-hover--midnight color--white"
                >
                  {!loading ? t("Subscribe") : <Loader />}
                </button>
              </Form>

              <IconSuccess
                className={
                  showSuccess ? "icon-success visible" : "icon-success"
                }
              />
            </div>

            {!showSuccess && <IconPlane className="icon" />}
          </div>
        </div>
      </Container>
    </StyledNewsletterBlog>
  )
}

export default NewsletterBlog
