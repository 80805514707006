import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import { colors } from "utils/variables/"

// Icons
import IconExternal from "assets/icons/icon-external-link.inline.svg"

const StyledExternalLinkButton = styled.a`
  display: inline-flex;
  align-items: center;
  padding: 16px 32px;
  color: ${colors.midnight};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  border: 2px solid ${colors.midnight};
  border-radius: 100px;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.midnight};
    color: ${colors.white};

    svg {
      * {
        fill: ${colors.white};
      }
    }
  }

  svg {
    margin-left: 8px;

    * {
      transition: all 0.3s ease;
    }
  }
`

const ExternalLinkButton = props => {
  return (
    <StyledExternalLinkButton {...props}>
      {props.children}
      <IconExternal />
    </StyledExternalLinkButton>
  )
}

export default ExternalLinkButton
