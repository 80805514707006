import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import { colors } from "utils/variables/"

// Layout
import Layout from "layouts/layout-primary.js"

// Context
import ContextConsumer from "components/context"

// Components
import Seo from "components/seo/"
import NewsletterBlog from "components/newsletter-blog/"

// Sections
import Hero from "sections/blog/hero"
import Posts from "sections/blog/posts"

const Blog = props => {
  const {
    allContentfulBlogPost: { nodes: posts },
  } = props.data

  const { t } = useTranslation()

  return (
    <Layout menuColor={colors.midnight}>
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({ useYellowFooter: false })
          }
          if (data.darkMenu) {
            set({ darkMenu: false })
          }
          if (!data.showFooter) {
            set({ showFooter: true })
          }
          if (data.simplifiedFooter) {
            set({ simplifiedFooter: false })
          }
        }}
      </ContextConsumer>
      <Seo
        title={t("Blog - Indicius Design Agency")}
        description={t(
          "A blog by Indicius: A digital agency working to design a more human-centered world. Find articles on UX/UI design, development, branding, work culture, project management, and growing your business."
        )}
      />
      <Hero />
      <Posts data={posts} />
      <NewsletterBlog />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allContentfulBlogPost(
      filter: { node_locale: { eq: $language } }
      sort: { fields: publishing, order: DESC }
    ) {
      nodes {
        slug
        title
        categories
        excerpt {
          excerpt
        }
        featuredImage {
          gatsbyImageData(width: 650)
        }
      }
    }
  }
`

export default Blog
