import React from "react"

// Libraries
import styled, { keyframes } from "styled-components"

// Utils
import { colors } from "utils/variables/"

const dotCollisionBefore = keyframes`
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-15px);
  }
`

const dotCollisionAfter = keyframes`
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(15px);
  }
`

const StyledLoader = styled.div`
  .dots {
    position: relative;
    width: 10px;
    height: 10px;
    margin: 0 auto;
    border-radius: 5px;
    background-color: ${colors.white};
    color: ${colors.white};
  }

  .dots::before,
  .dots::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dots::before {
    left: -10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${colors.white};
    color: ${colors.white};
    animation: ${dotCollisionBefore} 1s infinite ease-in;
  }

  .dots::after {
    left: 10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${colors.white};
    color: ${colors.white};
    animation: ${dotCollisionAfter} 1s infinite ease-in;
    animation-delay: 0.5s;
  }
`

const Loader = ({ className }) => (
  <StyledLoader className={className}>
    <div className="dots"></div>
  </StyledLoader>
)

export default Loader
